@if (routingParams(); as routingParams) {
  @if (!!routingParams) {
    <aside class="navigation-bar--side" [class.active]="!zoomed()">
      <div class="navigation-bar--side__details">
        <nav class="full-navigation non-selectable">
          <div class="user">
            <div class="user__image non-selectable" [title]="'user-photo' | translate">
              @if (elementLoaded) {
                <img ngSrc="assets/img/app/avatar.webp" fill priority (error)="elementLoaded = false" [alt]="'user-photo' | translate" />
              }
              @if (!elementLoaded) {
                <nz-skeleton-element nzType="image" [nzActive]="true"></nz-skeleton-element>
              }
            </div>
            <div class="user__details">
              <h2 class="user__details__name">John Smith</h2>
              <p class="user__details__mail">johnsmith&#64;gmail.com</p>
            </div>
          </div>
          <section class="full-navigation__navigation">
            <h2>{{ 'navigation' | translate }}</h2>
            <div class="links">
              @for (menuItem of navigationLinks(); track menuItem.route) {
                <a routerLinkActive="link-active" [routerLink]="menuItem.route">
                  {{ menuItem?.translatePath || 'menu' | translate }}
                </a>
              }
            </div>
          </section>
          <section class="full-navigation__other">
            <h2>{{ 'account' | translate }}</h2>
            <div class="links">
              <a [routerLink]="pages.SETTINGS" routerLinkActive="link-active">{{ 'settings' | translate }}</a>
              <a [routerLink]="pages.SAVED_CARDS" routerLinkActive="link-active">{{ 'saved-cards' | translate }}</a>
              <a [routerLink]="pages.ORDER_HISTORY" routerLinkActive="link-active">{{ 'order-history' | translate }}</a>
            </div>
          </section>
          <section class="full-navigation__contact">
            <div class="links">
              <a [routerLink]="pages.FEEDBACK" routerLinkActive="link-active">{{ 'give-feedback' | translate }}</a>
              <a [routerLink]="pages.CONTACT" routerLinkActive="link-active">{{ 'contact' | translate }}</a>
              <a [routerLink]="pages.TERMS_AND_CONDITIONS" routerLinkActive="link-active">{{ 'terms-and-conditions' | translate }}</a>
              <a [routerLink]="pages.FAQ" routerLinkActive="link-active">{{ 'faq' | translate }}</a>
            </div>
          </section>
        </nav>
      </div>
    </aside>
  }
  <main [class.active]="!zoomed()" class="clickable" tabindex="0" (click)="zoomInApp($event)" (keyup)="zoomInApp($event)">
    @if (!!routingParams) {
      <app-menu-icon tabindex="0" (click)="toggleZoom($event)" class="clickable"></app-menu-icon>
    }
    <div class="router">
      <router-outlet #outlet="outlet"></router-outlet>
    </div>
    @if (appMenuBarShown()) {
      <app-menu-bar class="non-selectable navigation-bar--bottom" [@removeAnimation] [@addAnimation]></app-menu-bar>
    }
  </main>
}
